import React, { useState, useEffect } from 'react';
import Layout from '@/components/common/layout';
import Container from '@/components/common/container';
import SEO from '@/components/seo';
import YouTubeIframe from '@/components/common/youtube';

const SuccessVideo = () => {
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    //get video url from local storage
    const url = localStorage.getItem('thVideoUrl');
    if (url) {
      setVideoUrl(url);
    }
  }, []);

  if (videoUrl === '') {
    return '';
  }

  return (
    <div className="relative">
      <div className="absolute inset-0 z-0 h-full w-full">
        <div className="container mx-auto h-full w-full max-w-6xl overflow-hidden">
          <div className="h-full w-full translate-y-28 transform bg-[url(/eventsgraphic.svg)] bg-[length:150%] bg-top bg-no-repeat md:translate-y-10 md:bg-[length:100%] lg:bg-contain"></div>
        </div>
      </div>
      <Layout>
        <Container className="relative py-16 md:py-24 lg:py-28">
          <h1 className="mx-auto mb-6 max-w-3xl text-center text-3xl lg:text-5xl">
            Thank you for registering!
          </h1>
          <p className="mx-auto mb-8 mt-4 max-w-4xl text-center text-lg font-light md:mt-0 lg:mb-12 lg:text-xl">
            As promised, enjoy exclusive access to our "Announcing Galileo LLM
            Studio: A Smarter Way to Build LLM Applications".
          </p>
          <YouTubeIframe link={videoUrl} />
        </Container>
      </Layout>
    </div>
  );
};

export default SuccessVideo;

export const Head = () => (
  <>
    <meta name="robots" content="noindex"></meta>
    <SEO title={`Thank You!`} />;
  </>
);

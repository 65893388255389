import React from 'react';

const YouTubeIframe = ({ link }) => {
  link = link.replace('watch?v=', 'embed/');
  return (
    <div
      className="video relative my-7"
      style={{
        position: 'relative',
        paddingBottom: '56.25%' /* 16:9 */,
        height: 0,
      }}
    >
      <div className="absolute inset-0 bg-white" />
      <div className="absolute inset-0 bg-gradient-to-br from-orangey-yellow via-reddish-magenta to-purply-blue opacity-10" />
      <iframe
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        src={link}
        allowFullScreen={true}
      />
    </div>
  );
};

export default YouTubeIframe;
